<template>
  <div class="tableList">
     <global-table ref="addDetailtable" :tableField="tableField"  :tableData="bankData" ></global-table>
  </div>
</template>

<script>
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { tableField } from './js/AddpayPlandialog'
export default {
  name: 'AddpayPlandialog',
  props: ['bankData'],
  components: { GlobalTable },
  data () {
    return {
      tableField: tableField,
      tableData: this.bankData
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style scoped>
.tableList{
padding: 0px;
}
</style>
